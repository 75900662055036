import React, { Suspense, lazy, useState, useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SEOHelmet from './components/SEOHelmet';
import LoadingSpinner from './components/LoadingSpinner';
import RedirectHandler from './components/RedirectHandler';

// Lazy load components
const AboutMe = lazy(() => import('./pages/AboutMe'));
const Bits = lazy(() => import('./pages/Bits'));
const Bites = lazy(() => import('./pages/Bites'));
const BitesPicks = lazy(() => import('./pages/BitesPicks'));
const Blog = lazy(() => import('./pages/Blog'));
const Impressum = lazy(() => import('./pages/Impressum'));

const AppContainer = styled.div`
  padding-top: 60px; // Add space for the fixed navbar
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile('ontouchstart' in window || navigator.maxTouchPoints > 0);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const cursor = document.getElementById('cursor');
      const onMouseMove = (e) => {
        cursor.style.left = `${e.clientX}px`;
        cursor.style.top = `${e.clientY}px`;
      };
      document.addEventListener('mousemove', onMouseMove);
      return () => {
        document.removeEventListener('mousemove', onMouseMove);
      };
    }
  }, [isMobile]);

  return (
    <Router>
      <AppContainer>
        <Navbar />
        <ContentContainer>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<><SEOHelmet title="About" description="Learn about Adrian Henkel, a Data Scientist with a background in Bioinformatics." /><Navigate to="/about" replace /></>} />
              <Route path="/about" element={<><SEOHelmet title="About" description="Learn about Adrian Henkel, a Data Scientist with a background in Bioinformatics." /><AboutMe /></>} />
              <Route path="/bits" element={<><SEOHelmet title="Bits" description="Explore coding tips and tricks from Adrian Henkel." /><Bits /></>} />
              <Route path="/bites-recipes" element={<><SEOHelmet title="Bites Recipes" description="Discover Adrian Henkel's favorite recipes and culinary adventures." /><Bites /></>} />
              <Route path="/bites-picks" element={<><SEOHelmet title="Bites Picks" description="Adrian Henkel's top picks for restaurants, kitchen gadgets, and more." /><BitesPicks /></>} />
              <Route path="/blog" element={<><SEOHelmet title="Blog" description="Read Adrian Henkel's thoughts on data science, bioinformatics, and more." /><Blog /></>} />
              <Route path="/impressum" element={<><SEOHelmet title="Impressum" description="Legal information about Adrian Henkel's website." /><Impressum /></>} />
              <Route path="*" element={<RedirectHandler />} />
            </Routes>
          </Suspense>
        </ContentContainer>
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;
